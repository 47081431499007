import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import TopNav from "./../navigation/TopNav";
import BottomNavigation from "./../navigation/BottomNavigation";
import Loader from "./../utils/Loader";
import Icon from "../utils/Icon";
import FilterIcon from "../compare/FilterIcon";
import { Link } from "react-router-dom";
import endpoint from "../../api";
import { AppContext } from "./../App";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

const BACK_BUTTON_KEY = "saveramericas.back_button";
const OPERATORS_KEY = "saveramericas.operators";

export default function OperatorPage({ match }) {
  const { selectedLanguage } = useContext(AppContext);

  const [loading, setLoading] = useState();
  const [operator, setOperator] = useState([]);
  const [content, setContent] = useState({
    title: "",
    body: "",
    image: "",
    contact: {
      address: "",
      link: "",
      fax: "",
      email: "",
      phone: "",
      website: "",
    },
  });

  useEffect(() => {
    var operatorsJSON = localStorage.getItem(OPERATORS_KEY);
    setOperator(
      JSON.parse(operatorsJSON).filter((operator) =>
        operator.CompanyLink.endsWith(match.url)
      )
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    endpoint
      .get(`${selectedLanguage.value}/operator/${match.params.slug}/`)
      .then((response) => {
        setLoading(false);
        setContent(response.data);
      });
  }, [selectedLanguage.value, match.params.slug]);

  const back_button = localStorage.getItem(BACK_BUTTON_KEY);

  return (
    <div className="screen-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <TopNav>
            <Link to={back_button}>
              <Icon icon={"back"} width={20} height={20} />
            </Link>
            <h1>{content.title}</h1>
          </TopNav>
          <div className="screen-content screen-content--white">
            <div className="container">
              {content.image && (
                <div>
                  <img src={content.image} />
                </div>
              )}
              <h3>{content.title}</h3>
              {content.contact.address && (
                <p
                  dangerouslySetInnerHTML={{ __html: content.contact.address }}
                />
              )}
              {content.contact.phone && (
                <p>
                  <b>
                    <FormattedMessage id="label.phone" />:
                  </b>{" "}
                  {content.contact.phone}
                </p>
              )}
              {content.contact.fax && (
                <p>
                  <b>
                    <FormattedMessage id="label.fax" />:
                  </b>{" "}
                  {content.contact.fax}
                </p>
              )}
              {content.contact.email && (
                <p>
                  <b>
                    <FormattedMessage id="label.email" />:
                  </b>{" "}
                  {content.contact.email}
                </p>
              )}
              {content.contact.website && (
                <p>
                  <b>
                    <FormattedMessage id="label.website" />:
                  </b>{" "}
                  <a href="content.contact.website" target="_blank">
                    {content.contact.website}
                  </a>
                </p>
              )}
            </div>
            <div className="container text-container">
              <div dangerouslySetInnerHTML={{ __html: content.body }} />
              <Table operator={operator} />
            </div>
          </div>
        </>
      )}
      <BottomNavigation />
    </div>
  );
}

function Table({ operator }) {
  const operatorElements = operator.map((operator, key) => {
    let TypeDeliveryFromElements = operator.TypeDeliveryFromPath.map(
      (path, key) => {
        return <FilterIcon path={path} key={key} />;
      }
    );

    let TypeDeliveryToElements = operator.TypeDeliveryToPath.map(
      (path, key) => {
        return <FilterIcon path={path} key={key} />;
      }
    );

    let TypeDeliveryName = [
      operator.TypeDeliveryFromName.join(" "),
      operator.TypeDeliveryToName.join(" "),
    ];
    TypeDeliveryName = TypeDeliveryName.join(" to ");

    let TypeElements;

    if (operator.TypePath.length > 0) {
      TypeElements = operator.TypePath.map((path, key) => {
        return <FilterIcon path={path} key={key} />;
      });
    }

    return (
      <tr key={key}>
        <td>
          <div className="type-icon">
            {TypeElements}
            <span>{operator.TypeName}</span>
          </div>
        </td>
        <td>
          <div className="type-icon">
            {TypeDeliveryFromElements}
            {TypeDeliveryToElements && (
              <>
                <Icon icon={"arrow_right"} />
                {TypeDeliveryToElements}
              </>
            )}
            <span>{TypeDeliveryName}</span>
          </div>
        </td>
        <td>
          <div className="type-icon">
            <FilterIcon path={operator.TimeDeliveryPath} />
            <span>{operator.TimeDeliveryName}</span>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <table className="operator-options">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="label.type" />
          </th>
          <th>
            <FormattedMessage id="label.method" />
          </th>
          <th>
            <FormattedMessage id="label.speed" />
          </th>
        </tr>
      </thead>
      <tbody>{operatorElements}</tbody>
    </table>
  );
}
