import React, { useContext } from "react";
import SlickCarousel from "./SlickCarousel";
import LanguageSelect from "./LanguageSelect";
import Button from "./Button";
import { AppContext } from "./../App";
import SaverAmericasLogo from "../../assets/img/saveramericas-logo.svg";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export default function Index() {
  const { languages } = useContext(AppContext);

  const defaultLanguage = languages.find(() => true);

  return (
    <div className="screen-container home">
      <div className="container">
        <div className="screen-content">
          <img src={SaverAmericasLogo} alt="SaverAmericas" />
          <SlickCarousel />
          <div className="bottom-actions">
            {defaultLanguage && (
              <LanguageSelect size={"select--large"} name={"language"} />
            )}
            <Button />
          </div>
        </div>
      </div>
    </div>
  );
}
